import React, { useCallback, useEffect, useState } from 'react';

import { SelectableList, SelectableListOption } from 'components';
import { useMenuHideWindow, useScrollHandler } from 'hooks';
import useSpotifyApi from 'hooks/useSpotifyApi';

import ViewOptions, {NowPlayingView} from '../';

const PlaylistsView = () => {
  useMenuHideWindow(ViewOptions.playlists.id);
  const [options, setOptions] = useState<SelectableListOption[]>([]);
  const { loading, data, error } = useSpotifyApi<
    SpotifyApi.ListOfCurrentUsersPlaylistsResponse
  >("me/playlists?limit=50");

  const handleData = useCallback(() => {
    setOptions(
      data!.items.map((playlist, idx) => ({
        label: playlist.name,
        //viewId: ViewOptions.playlist.id,
        value: () => (
            <NowPlayingView uri={playlist.uri} />
        ),
        uris: data!.items.map( it => it.uri),
        songIndex: idx
      }))
    );
  }, [data]);

  useEffect(() => {
    if (data?.items && !options.length && !error) {
      handleData();
    }
  }, [data, error, handleData, options.length]);

  const [index] = useScrollHandler(ViewOptions.playlists.id, options);

  return (
    <SelectableList loading={loading} options={options} activeIndex={index} />
  );
};

export default PlaylistsView;
