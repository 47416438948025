import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Interface from "../../App/Interface";
import { ScrollWheel } from "components";
import { useEventListener } from "../../hooks";
import { getThemeForValueFromString, Theme } from "../constants/Theme";
import { INSTALLED, PLATFORM, BROWSER } from "components/constants/Platform";

interface ContainerProps {
  theme: Theme;
}

const Title = styled.h3`
  text-align: center;
  text-transform: uppercase;
  font-weight: 200;
  padding-top: 20px;
  margin-bottom: -10px;
`;

const Subtitle = styled.p`
  text-align: center;
  padding-top: 05px;
`;
const Container = styled.div<ContainerProps>`
  position: relative;
  height: var(--vh, 1vh);
  margin: auto;
  // max-height: 36.5em;
  width: 100vh;
  border-radius: 30px;

  background: var(--bg-color);
  --bg-color: linear-gradient(
    180deg,
    ${({ theme }) => theme.centerButtonBoxShadowColor} 0%,
    ${({ theme }) => theme.centerButtonBoxShadowColor} 100%
  );
  // -webkit-box-reflect: below 0px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(50%, transparent), to(rgba(250, 250, 250, 0.3)));
  // animation: descend 1.5s ease;
  @media (prefers-color-scheme: dark) {
    box-shadow: inset 0 0 2.4em var(--theme-color);
  }

  @media screen and (max-width: 400px) {
    animation: none;
    border-radius: 0;
    -webkit-box-reflect: unset;
  }

  @keyframes descend {
    0% {
      transform: scale(0.3);
      opacity: 0;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

// document.documentElement.style.setProperty("--bg-color");

const Shell = () => {
  const [theme, setTheme] = useState(
    getThemeForValueFromString(localStorage.getItem("theme"))
  );

  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [isSafari, setIsSafari] = useState(false);

  useEventListener("changeTheme", onThemeChange);

  function onThemeChange(e: CustomEvent) {
    // console.log("onThemeChange"+e.detail.value);
    setTheme(getThemeForValueFromString(localStorage.getItem("theme")));
    const bgColor = getThemeForValueFromString(localStorage.getItem("theme"))
      .centerButtonBackgroundColor!;
    document.documentElement.style.setProperty("--bg-color", bgColor);

    document
      .querySelector('meta[name="theme-color"]')
      ?.setAttribute("content", bgColor);
  }

  useEffect(() => {
    if (PLATFORM === "ios" || PLATFORM === "android") {
      if (!INSTALLED) {
        setIsLoggedIn(false);
      } else {
        setIsLoggedIn(true);
      }
    }

    if (BROWSER === "safari") {
      setIsSafari(true);
    }
  }, [setIsLoggedIn, setIsSafari]);

  return (
    <Container theme={theme}>
      {!isLoggedIn ? (
        <>
          <Title>How to Install Rewound</Title>
          {isSafari ? (
            <Subtitle>add to homescreen from safari</Subtitle>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      <Interface />
      <ScrollWheel />
    </Container>
  );
};

export default Shell;
