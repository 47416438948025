import React from "react";

import { useSpotifyService } from "services/spotify";
import { useWindowService } from "services/window";
import styled from "styled-components";

import { INSTALLED } from "components/constants/Platform";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 6px;
  height: 28px;
  background: linear-gradient(#727272 0%, ##3e403f 100%);
  box-sizing: border-box;
`;

const Text = styled.h3`
  margin: 0 auto;
  font-size: var(--label-font-size);
  color: black !important;
`;

const IconContainer = styled.div`
  display: flex;
`;

const Icon = styled.img`
  max-height: 12px;
  margin-left: 8px;
`;

const Header = () => {
  const { headerTitle } = useWindowService();
  const { playerState } = useSpotifyService();
  const { loggedIn } = useSpotifyService();

  const playing = playerState && !playerState.paused;
  const paused = playerState && playerState.paused;

  return headerTitle ? (
    <Container>
      {!INSTALLED && !loggedIn ? (
        <></>
      ) : (
        <>
          <Text>{headerTitle}</Text>
          <IconContainer>
            <Icon src="spotify-icon.png" />
            {playing && <Icon src="play.svg" />}
            {paused && <Icon src="pause.svg" />}
          </IconContainer>
        </>
      )}
    </Container>
  ) : null;
};

export default Header;
