import { useCallback, useEffect, useState } from "react";
import { getTokens } from "utils";

export interface FetchHook<TType> {
  loading: boolean;
  data: TType;
  error: Error;
}

const useFetch = <TType>(
  url: string,
  options?: RequestInit,
  shouldFetch?: boolean,
  shouldRefresh: boolean = false
) => {
  const [data, setData] = useState<TType>();
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(false);

  const handleFetch = useCallback(async () => {
    setLoading(true);
    try {
      const res = await fetch(url, options);
      console.log("Error" + res.status);

      if (res.status === 401) {
        const { accessToken } = await getTokens(401);
        console.log(accessToken);
        window.location.reload();
      }
      const json = await res.json();

      setData(json);
    } catch (error: any) {
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  }, [options, url]);

  useEffect(() => {
    if (!loading && !data && !error && shouldFetch) {
      handleFetch();
    }
  }, [data, error, handleFetch, loading, shouldFetch]);
  return { data, error, loading };
};

export default useFetch;
