import React, { useEffect } from "react";
import SpotifyProvider from "services/spotify";
import WindowProvider from "services/window";
import styled, { createGlobalStyle } from "styled-components";
import { getThemeForValueFromString } from "../components/constants/Theme";

import Shell from "../components/Shell";

let vh = window.innerHeight;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`);

const GlobalStyles = createGlobalStyle`
   body {
      font-family: "pix Chicago", sans-serif;
      font-size: var(--label-font-size);
      box-sizing: border-box;
        touch-action: manipulation;
      @media (prefers-color-scheme: dark) {
        background: black;
      }
     overflow:hidden;

     letter-spacing: -0.06px;
     color: var(--font-color);
     user-select: none;
     background: var(--bg-color);
     height:100vh;
   }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: var(--theme-color);
  background: var(--bg-color);
  @media screen and (min-width: 750px) {
    display: flex;
  }

  min-height: -webkit-fill-available;
`;
//

//

const App: React.FC = () => {
  useEffect(() => {
    const bgColor = getThemeForValueFromString(localStorage.getItem("theme"))
      .centerButtonBackgroundColor!;
    document.documentElement.style.setProperty("--bg-color", bgColor);
    document
      .querySelector('meta[name="theme-color"]')
      ?.setAttribute("content", bgColor);
  }, []);

  return (
    <Container>
      <GlobalStyles />
      <SpotifyProvider>
        <WindowProvider>
          <Shell />
        </WindowProvider>
      </SpotifyProvider>
    </Container>
  );
};

export default App;
