export class Theme {
  centerButtonBoxShadowColor: string | undefined;
  centerButtonBackgroundColor: string | undefined;
  knobGradientStartColor: string | undefined;
  knobGradientEndColor: string | undefined;
  knobButtonTextColor: string | undefined;
  wheelColor: string | undefined;

  constructor(
    centerButtonBoxShadowColor: string | undefined,
    centerButtonBackgroundColor: string | undefined,
    knobGradientStartColor: string | undefined,
    knobGradientEndColor: string | undefined,
    knobButtonTextColor: string | undefined,
    wheelColor: string | undefined
  ) {
    this.centerButtonBoxShadowColor = centerButtonBoxShadowColor;
    this.centerButtonBackgroundColor = centerButtonBackgroundColor;
    this.knobGradientStartColor = knobGradientStartColor;
    this.knobGradientEndColor = knobGradientEndColor;
    this.knobButtonTextColor = knobButtonTextColor;
    this.wheelColor = wheelColor;
  }
}

export const BlackTheme = new Theme(
  "#000000",
  "#000000",
  "#202020",
  "#4b4b4b",
  "#AFAFAF",
  "#272727"
);

export const WhiteTheme = new Theme(
  "#F0EEEE",
  "#F0EEEE",
  "#E4E4E4",
  "#F4F4F4",
  "#FFFFFF",
  "#CDCDCE"
);

export const RedTheme = new Theme(
  "#0C0C0A",
  "#0C0C0A",
  "#FF4848",
  "#FF4b4b",
  "#FFFFFF",
  "#E61A31"
);

export const getThemeForValueFromEvent = (e: CustomEvent | undefined) => {
  // if (e!==undefined) {
  //     if (e.detail.value === 'White') {
  //         return WhiteTheme;
  //     } else if (e.detail.value === 'Red') {
  //         console.log("Red");
  //         return RedTheme;
  //     }
  // }
  console.log("SHOULD NOT HAPPEN");
  return WhiteTheme;
};

export const getThemeForValueFromString = (value: string | null) => {
  if (value !== undefined) {
    if (value === "White") {
      return WhiteTheme;
    } else if (value === "Red") {
      console.log("Red");
      return RedTheme;
    } else if (value === "Black") {
      console.log("Red");
      return BlackTheme;
    }
  }
  return WhiteTheme;
};
