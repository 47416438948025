import React from "react";

import styled, { css } from "styled-components";

import { SelectableListOption } from "./";

interface ContainerProps {
  isActive: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  overflow: auto;
  color: black !important;

  ${(props) =>
    props.isActive &&
    css`
      color: white;
      background: linear-gradient(#000000 0%, #000000 100%);
    `};
`;

// const Image = styled.img`
//   height: 3rem;
//   width: 3rem;
// `;

const Icon = styled.img`
  margin-left: auto;
  margin-top: 6px;
  height: 20px;
  width: 20px;
`;
const Label = styled.h3`
  margin: 0;
  padding: 2px 15px;
  font-size: var(--label-font-size);
  white-space: nowrap;
  overflow: hidden;
  color: black;
  text-overflow: ellipsis;
`;

const ActiveLabel = styled.h3`
  margin: 0;
  padding: 2px 15px;
  font-size: var(--label-font-size);
  white-space: nowrap;
  overflow: hidden;
  color: var(--screen-color);
  text-overflow: ellipsis;
`;

interface Props {
  option: SelectableListOption;
  isActive: boolean;
}

const SelectableListItem = ({ option, isActive }: Props) => {
  return (
    <Container isActive={isActive}>
      {isActive && <ActiveLabel>{option.label}</ActiveLabel>}
      {!isActive && <Label>{option.label}</Label>}
      {isActive && <Icon src="arrow_right.svg" />}
    </Container>
  );
};

export default SelectableListItem;
