const UA = navigator.userAgent;

const IOS = UA.match(/iPhone|iPad|iPod/);
const ANDROID = UA.match(/Android/);
const CHROME = UA.match(/Chrome/);
const SAFARI = UA.match(/Safari/);

export const PLATFORM = IOS ? "ios" : ANDROID ? "android" : "unknown";

const standalone = window.matchMedia("(display-mode: standalone)").matches;

export const INSTALLED = !!(standalone || (IOS && !UA.match(/Safari/)));

export const BROWSER = CHROME ? "chrome" : SAFARI ? "safari" : UA;
