import React, { useCallback, useEffect, useState } from "react";

import ViewOptions, * as Views from "App/views";
import { SelectableList, SelectableListOption } from "components";
import { useScrollHandler } from "hooks";
import { useSpotifyService } from "services/spotify";
import { useWindowService } from "services/window";
import { _saveTokens } from "../../../utils";
import { INSTALLED, PLATFORM } from "components/constants/Platform";

const clientId = "a7c18a65cef84149a984ff44bc54d0bb";
const redirectUri = window.location.origin;
const scopes = [
  "user-read-currently-playing",
  "user-read-playback-state",
  "user-top-read",
  "ugc-image-upload",
  "playlist-read-collaborative",
  "playlist-modify-private",
  "playlist-read-private",
  "playlist-modify-public",
  "user-read-email",
  "user-read-private",
  "user-modify-playback-state",
  "user-read-recently-played",
  "user-follow-read",
  "user-follow-modify",
  "streaming",
  "user-library-read",
  "user-library-modify",
];

const initialOptions: SelectableListOption[] = [
  {
    label: "Spotify Signin",
    value: "",
    link: `https://accounts.spotify.com/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes.join(
      "%20"
    )}&response_type=code&state=34fFs29kd09&show_dialog=true`,
  },
  {
    label: "Apple Music Signin",
    value: "",
    link: `https://louisanslow.substack.com/p/rewoundfm-for-music-other-services`,
  },
  {
    label: "Other Streaming Services",
    value: "",
    link: `https://louisanslow.substack.com/p/rewoundfm-for-music-other-services`,
  },
];

const installationOption: SelectableListOption[] = [
  // {
  //   label: "Add to Homescreen",
  //   value: "",
  //   link: ``,
  // },
  // {
  //   label: "from Safari to Install",
  //   value: "",
  //   link: ``,
  // },
];

const checkInstallation = () => {
  if (PLATFORM === "ios" || PLATFORM === "android") {
    if (!INSTALLED) {
      return false;
    }
  } else {
    window.location.href = "https://browser.rewound.fm";
  }
};
// Get the hash of the url
window.location.hash
  .substring(1)
  .split("&")
  .reduce(function (initial: any, item) {
    console.log(JSON.stringify(initial));
    if (item) {
      const parts = item.split("=");
      initial[parts[0]] = decodeURIComponent(parts[1]);
      _saveTokens(
        initial["access_token"],
        initial["access_token"],
        initial["expires_in"]
      );
    }
    return initial;
  }, {});
window.location.hash = "";

const AuthView = () => {
  const { resetWindowStack } = useWindowService();
  const [options, setOptions] = useState(initialOptions);
  const [index] = useScrollHandler(ViewOptions.auth.id, options);
  const { loggedIn } = useSpotifyService();

  const handleCheckLogin = useCallback(() => {
    console.log("handleCheckLogin" + loggedIn);
    console.log("Hash" + window.location);
    if (loggedIn) {
      resetWindowStack({
        id: ViewOptions.home.id,
        type: Views.WINDOW_TYPE.SPLIT,
        component: Views.HomeView,
      });
    }
  }, [loggedIn, resetWindowStack]);

  const handleInstallationCheck = useCallback(() => {
    if (checkInstallation() === false && !loggedIn) {
      setOptions(installationOption);
      // if (BROWSER === "chrome") {
      //   document.documentElement.style.setProperty(
      //     "--screen-color",
      //     "url('https://media4.giphy.com/media/iF78u6qTLReOQUMer4/giphy.gif?cid=790b7611195c402e03060da7ee31ec1b2efb05172bff8000&rid=giphy.gif&ct=g   ')"
      //   );
      // } else {
      //   document.documentElement.style.setProperty(
      //     "--screen-color",
      //     "url('https://media4.giphy.com/media/G09rcSfxzamQVM0pj4/giphy.gif?cid=790b7611e2d50328f1c70dd95c21624615e0d744315a964c&rid=giphy.gif&ct=g')"
      //   );
      // }

      document.documentElement.style.setProperty(
        "--screen-color",
        "url('https://media4.giphy.com/media/G09rcSfxzamQVM0pj4/giphy.gif?cid=790b7611e2d50328f1c70dd95c21624615e0d744315a964c&rid=giphy.gif&ct=g')"
      );
    } else {
      document.documentElement.style.setProperty("--screen-color", "#caccbf");
    }
  }, [setOptions, loggedIn]);

  useEffect(() => {
    handleCheckLogin();
    handleInstallationCheck();
  }, [handleCheckLogin, handleInstallationCheck]);

  return <SelectableList options={options} activeIndex={index} />;
};

export default AuthView;
