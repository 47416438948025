import * as THREE from 'three';

class PlayClickModule{

    sound: THREE.Audio[] | null = null;
    initialized: boolean = false;
    playCount: number = 0;

    constructor(public readonly filename: string, public readonly listenerCount: number) {

    }

    init(){
        return new Promise(resolve => {
            if(this.initialized){
                return;
            }
            const listener = new THREE.AudioListener();
            const audioLoader = new THREE.AudioLoader();
            audioLoader.load( this.filename, (buffer) => {
                this.sound = [];
                for(let i = 0; i < this.listenerCount; i++) {
                    let sound = new THREE.Audio(listener);
                    sound.setBuffer( buffer );
                    sound.setVolume( 1 );
                    sound.setPlaybackRate(1);
                    this.sound[i] = sound;
                }
                this.initialized = true;
                console.log("sound ",this.filename, "loaded");
                resolve(true);
            });
        });
    }

    async play(){
        if(!this.initialized || THREE.AudioContext.getContext().state === "suspended"){
            await THREE.AudioContext.getContext().resume();
            await this.init();
        }
        if(!this.sound){
            console.log("no sound");
            return;
        }

        this.sound[this.playCount].play();
        this.playCount = (this.playCount + 1) % this.listenerCount;
    }
}

export const CLICK = new PlayClickModule("clicky.wav", 10);